import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28525d26"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "hmtk-logo",
  "data-componentid": "menu-hmtk-logo"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-cmiBlue text-center pt-0 mt-0" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text-gray-400 text-center" }
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = { class: "mx-2 text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    "content-id": "main-content",
    type: "overlay",
    disabled: !$setup.currentUser
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, {
        color: "white",
        style: {"background-color":"white"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, {
            color: "white",
            "router-link": "/dashboard"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("img", {
                  src: $options.userLogo,
                  class: "p-4"
                }, null, 8, _hoisted_2)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { color: "white" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, { id: "progress-list" }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("MENU.TRACK")), 1),
              _createVNode(_component_ion_item, { class: "ion-margin-bottom" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t("MENU.EARN_YOUR_INCENTIVE")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.incentiveProgress, (p, i) => {
                return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                  "auto-hide": "false",
                  key: i
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      disabled: p.disabled,
                      "router-direction": "root",
                      "router-link": p.url,
                      lines: "none",
                      detail: "false",
                      class: _normalizeClass(["hydrated", { selected: $setup.selectedPath === p.url }])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "start",
                          ios: p.iosIcon,
                          md: p.mdIcon
                        }, null, 8, ["ios", "md"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(p.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["disabled", "router-link", "class"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, { id: "certificate-list" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { class: "ion-margin-bottom" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t("MENU.CERT")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.certificateProgress, (p, i) => {
                return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                  "auto-hide": "false",
                  key: i
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      disabled: p.disabled,
                      "router-direction": "root",
                      "router-link": p.url,
                      lines: "none",
                      detail: "false",
                      class: _normalizeClass(["hydrated", { selected: $setup.selectedPath === p.url }])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "start",
                          ios: p.iosIcon,
                          md: p.mdIcon
                        }, null, 8, ["ios", "md"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(p.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["disabled", "router-link", "class"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          (_ctx.stepAvailable('info-hub'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("MENU.INFO_HUB_MORE_INFO")), 1),
                _createElementVNode("p", _hoisted_6, [
                  _createVNode(_component_ion_button, {
                    color: "success",
                    size: $setup.currentUser.language == 'en' ? 'large' : 'small',
                    type: "button",
                    href: $setup.currentUser.info_hub_url
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("MENU.INFO_HUB")), 1)
                    ]),
                    _: 1
                  }, 8, ["size", "href"])
                ]),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("MENU.INFO_HUB_MESSAGE")), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_list, { id: "user-list" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userLinks, (p, i) => {
                return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                  "auto-hide": "false",
                  key: i
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      disabled: p.disabled,
                      onClick: ($event: any) => ($setup.selectedPath = p.url),
                      "router-direction": "root",
                      "router-link": p.url,
                      lines: "none",
                      detail: "false",
                      class: _normalizeClass(["hydrated", { selected: $setup.selectedPath === p.url }])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "start",
                          ios: p.iosIcon,
                          md: p.mdIcon
                        }, null, 8, ["ios", "md"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(p.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onClick", "router-link", "class"])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_ion_menu_toggle, { "auto-hide": "false" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    onClick: $setup.handleSignOut,
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        ios: $options.exitOutline,
                        md: $options.exitOutline
                      }, null, 8, ["ios", "md"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("MENU.SIGN_OUT")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["disabled"]))
}