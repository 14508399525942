import ApiService from "@/services/api.service";

const state = {
    course: false,
};

const getters = {
    course: (state: { course: any }) => {
        return state.course;
    },
};

const actions = {
    async progress(context: any, data: { step: any, module_completed: any, position: any }) {
        return new Promise((resolve, reject) => {
            try {
                ApiService.post('api/my-course', data).then(function(response) {
                    context.commit("auth/setUser", response.data.data, { root: true })

                    resolve(response.data.data)
                })
            } catch (error) {
                reject(error)
            }
        })
    },
    async generateCertificate(context: any, data: { step: any, module_completed: any, position: any }) {
        return new Promise((resolve, reject) => {
            try {
                ApiService.post('api/certificates', {}).then(function(response) {
                    context.commit("auth/setUser", response.data.data, { root: true })

                    resolve(response.data.data)
                })
            } catch (error) {
                reject(error)
            }
        })
    },
};

const mutations = {
    setCourse(state: { course: any }, course: any) {
        state.course = course;
    }
};

export const course = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
