<template>
    <div id="cmi-hmtk-logo" data-componentid="cmi-menu-hmtk-logo" class="pt-6 lg:pt-0 md:pt-0">
      <img v-if="$i18n.locale == 'en'" src="@/assets/CMI_HealthyMindsThrivingKids_Logo_Lockup_FullColor.svg" />
      <img v-if="$i18n.locale == 'es'" src="@/assets/CMI_HealthyMindsThrivingKids_Lockup_FullColor_Sp.svg" />
    </div>
</template>

<style scoped>
#cmi-hmtk-logo {
  background-color: #ffffff;
}
#cmi-hmtk-logo > img {
  display: block;
  margin: 0 auto;
  padding: 15px;
  max-width: 100%;
  max-height: 200px;
}
</style>
