
import { mapGetters } from "vuex";
import ButtonGroup from "@/components/ButtonGroup.vue";
import ApiService from "@/services/api.service";
import MainHeader from "@/components/MainHeader.vue";
import FormError from "@/components/FormError.vue";

export default {
  name: "PostQuestionnaire",
  components: {
    ButtonGroup,
    MainHeader,
    FormError,
  },
  data() {
    return {
      questions: {},
      answers: {},
      responseErrors: [],
    };
  },
  mounted() {
    this.loadQuestions();
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    selectOne() {
        return this.$t("SELECT_ONE");
    },
    textPlaceholder() {
        return this.$t("QUESTIONNAIRE.TEXT_PLACEHOLDER");
    }
  },
  methods: {
    async loadQuestions() {
      if (this.currentUser) {
        ApiService.setLocale(this.currentUser.language);
      }
      const questions = await ApiService.get("api/post-course-survey").then(
        function (response) {
          return response.data.data;
        }
      );

      this.questions = questions;
    },
    async handleSubmit() {
      const response = await ApiService.post(
        "api/post-course-survey",
        this.answers
      )
        .then((response) => {
          this.currentUser.post_survey = true;
          this.scrollToTop();
        })
        .catch((err) => {
          this.responseErrors = err.response.data.errors;
        });
    },
    scrollToTop() {
      if (this.surveyContent.value) {
        this.surveyContent.value.$el.scrollToTop(1000);
      }
    }
  },
};
