
import { homeOutline, homeSharp } from "ionicons/icons";

import { useRouter } from "vue-router";
export default {
  name: "MainHeader",
  props: {
    showBackButton: {
      type: Boolean,
      default: true,
    },
    homeLink: {
      type: String,
      default: "/dashboard",
    },
  },
  computed: {
    homeSharp() {
      return homeSharp;
    },
    homeOutline() {
      return homeOutline;
    },
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
};
