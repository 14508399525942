
import { mapGetters, mapActions } from "vuex";
import MainHeader from "@/components/MainHeader.vue";

export default {
  name: "Certificate",
  components: {
    MainHeader,
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  methods: {
    ...mapActions("course", ["generateCertificate"]),
  },
  created() {
    this.generateCertificate()
  },
};
