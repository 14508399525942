import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Login from "@/views/Login.vue";
import Landing from "@/views/Landing.vue";
import Register from "@/views/Register.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Dashboard from "@/views/Dashboard.vue";
import PreQuestionnaire from "@/views/PreQuestionnaire.vue";
import PostQuestionnaire from "@/views/PostQuestionnaire.vue";
import Incentives from "@/views/Incentives.vue";
import Referrals from "@/views/Referrals.vue";
import Module from "@/views/Module.vue";
import Certificate from "@/views/Certificate.vue";
import { store } from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Landing,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/dashboard',
    component: Dashboard,
  },
  {
    path: '/pre-questionnaire',
    component: PreQuestionnaire,
  },
  {
    path: '/course/:module',
    component: Module,
  },
  {
    path: '/post-questionnaire',
    component: PostQuestionnaire,
  },
  {
    path: '/incentives',
    component: Incentives,
  },
  {
    path: '/referrals',
    component: Referrals,
  },
  {
    path: '/certificate',
    component: Certificate,
  },
  {
    path: '/login',
    component: Login,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/register',
    component: Register,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
]

const router = createRouter({
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );

  store.dispatch('auth/getCurrentUser')
    .then(() => {
      if (onlyWhenLoggedOut) {
        return next("/dashboard");
      }

      const stepAvailableMethod = store.getters['auth/stepAvailable']
      const steps = [
        'pre-questionnaire',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        'post-questionnaire',
        'incentive',
        'info-hub',
        'referral',
        'certificate',
      ]
      const step = to.params.module ? to.params.module : to.path.replace('/', '')
      const routeIsAStep = steps.indexOf(step.toString()) >= 0

      if (routeIsAStep) {
        const stepAvailable = stepAvailableMethod(step.toString())
        if (!stepAvailable) {
          return next("/dashboard")
        }
        store.dispatch('course/progress', { step: step })
      }


      return next()
    })
    .catch(() => {
      // not logged in
      if (!isPublic) {
        return next('/');
      }

      return next()
    })
});

export default router
