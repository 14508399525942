import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ion-margin-bottom" }
const _hoisted_2 = { class: "my-0 py-0" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "ion-float-right"
}
const _hoisted_5 = { class: "my-0 py-0" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "ion-float-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_verify_alert = _resolveComponent("verify-alert")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_main_header, { showBackButton: false }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("MENU.DASHBOARD")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        fullscreen: true,
        color: "light"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.currentUser && !_ctx.currentUser.email_verified_at)
              ? (_openBlock(), _createBlock(_component_verify_alert, { key: 0 }))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_item, { color: "pink" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.$t("MENU.EARN_YOUR_INCENTIVE")), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("DASHBOARD.INCENTIVE_CTA")), 1)
                ])
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.requiredModules, (module) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: module.id,
                disabled: module.disabled,
                "router-link": module.url,
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, { class: "ion-align-items-center ion-justify-content-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            class: "ion-align-self-center ion-text-center",
                            sizeLg: "2",
                            sizeMd: "2",
                            sizeXs: "4"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: module.icon,
                                class: "w-24 mx-auto"
                              }, null, 8, _hoisted_3)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_col, {
                            sizeLg: "10",
                            sizeMd: "10",
                            sizeXs: "12"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("h3", null, [
                                _createTextVNode(_toDisplayString(module.name) + " ", 1),
                                (module.duration)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($options.secondsToMinutesSeconds(module.duration)), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(module.description), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["disabled", "router-link"]))
            }), 128)),
            _createVNode(_component_ion_item, { color: "burgundy" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.$t("DASHBOARD.CERTIFICATE_TITLE")), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("DASHBOARD.CERTIFICATE_CTA")), 1)
                ])
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.optionalModules, (module) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: module.id,
                disabled: module.disabled,
                "router-link": module.url,
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, { class: "ion-align-items-center ion-justify-content-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            class: "ion-align-self-center ion-text-center",
                            sizeLg: "2",
                            sizeMd: "2",
                            sizeXs: "4"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: module.icon,
                                class: "w-24 mx-auto"
                              }, null, 8, _hoisted_6)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_col, {
                            sizeLg: "10",
                            sizeMd: "10",
                            sizeXs: "12"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("h3", null, [
                                _createTextVNode(_toDisplayString(module.name), 1),
                                (module.duration)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($options.secondsToMinutesSeconds(module.duration)), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(module.description), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["disabled", "router-link"]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}