
import {alertController } from "@ionic/vue";
import { logIn, mail } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import MainHeader from "@/components/MainHeader.vue";
import FormError from "@/components/FormError.vue";

export default {
  name: "Login",
  components: {
    FormError,
    MainHeader,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      logIn,
      mail,
    };
  },
  data() {
    return {
      responseErrors: {},
      form: {
        email: "",
        locale: "en",
      },
    };
  },
  mounted() {
      const { locale } = useI18n();
      this.form.locale = locale;
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "responseMessage",
      "responseCode",
    ]),
  },
  methods: {
    ...mapActions("auth", ["forgotPassword"]),
    async handleSubmit() {
      this.responseErrors = {}
      this.forgotPassword(this.form)
        .then(async () => {
          const successAlert = await alertController.create({
            header: this.$t("FORGOT_PASSWORD.SUCCESS_HEADER"),
            subHeader: this.$t("FORGOT_PASSWORD.SUCCESS_SUB_HEADER"),
            message: this.$t("FORGOT_PASSWORD.SUCCESS_MESSAGE"),
            buttons: ["OK"],
          });
          await successAlert.present();
        })
        .catch(async (err: any) => {
          this.responseErrors = err
        });
    },
  },
};
