import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from '@/store'
import { createI18n } from 'vue-i18n'
import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';

import ApiService from "@/services/api.service";
import { TokenService } from "@/services/token.service";

import { languages } from '@/lang/index'
import { defaultLocale } from '@/lang/index'
const messages = Object.assign(languages)
const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: 'es',
  messages
})

import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/rhea/theme.css';
import 'primevue/resources/primevue.min.css';

import VueGtag from "vue-gtag";

const app = createApp(App)
  .use(IonicVue)
  .use(PrimeVue)
  .use(i18n)
  .use(router)
  .use(store)
  .use(VueGtag, {
    config: { id: "DC-11573830" }
  });

ApiService.init(process.env.VUE_APP_ROOT_API);
ApiService.setLocale(defaultLocale);

if (TokenService.getToken()) {
  ApiService.setHeader();
  ApiService.mount401Interceptor();
}

router.isReady().then(() => {
  app.mount('#app');
});

import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonRange,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

app.component('IonBackButton', IonBackButton);
app.component('IonButton', IonButton);
app.component('IonButtons', IonButtons);
app.component('IonCol', IonCol);
app.component('IonContent', IonContent);
app.component('IonGrid', IonGrid);
app.component('IonHeader', IonHeader);
app.component('IonIcon', IonIcon);
app.component('IonInput', IonInput);
app.component('IonItem', IonItem);
app.component('IonLabel', IonLabel);
app.component('IonList', IonList);
app.component('IonListHeader', IonListHeader);
app.component('IonMenu', IonMenu);
app.component('IonMenuButton', IonMenuButton);
app.component('IonMenuToggle', IonMenuToggle);
app.component('IonPage', IonPage);
app.component('IonRange', IonRange);
app.component('IonRow', IonRow);
app.component('IonSelect', IonSelect);
app.component('IonSelectOption', IonSelectOption);
app.component('IonText', IonText);
app.component('IonTextarea', IonTextarea);
app.component('IonTitle', IonTitle);
app.component('IonToolbar', IonToolbar);
