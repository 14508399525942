
import {
  alertController,
} from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/services/api.service";
import MainHeader from "@/components/MainHeader.vue";

export default {
  name: "Incentives",
  components: {
    MainHeader,
  },
  data() {
    return {
      rewards: {},
      loading: true,
    };
  },
  mounted() {
    this.loadIncentives();
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  methods: {
    ...mapActions("auth", ["getCurrentUser"]),
    async loadIncentives() {
      if (this.currentUser) {
        ApiService.setLocale(this.currentUser.language);
      }
      const incentives = await ApiService.get("api/incentives").then(function (
        response
      ) {
        return response.data.data;
      });
      this.rewards = incentives;
      this.loading = false;
    },
    async selectReward(rewardId: number) {
      this.loading = true;
      const payload = {
        incentive_id: rewardId,
      };
      const response = await ApiService.post("api/orders", payload)
        .then((response) => {
          this.currentUser.order = true;
          this.getCurrentUser()
        })
        .catch(async (err) => {
          this.responseErrors = err.response.data.errors.incentive_id;
          const errorAlert = await alertController.create({
              header: this.$t("ERROR"),
              subHeader: this.$t("INCENTIVES.VALIDATION_ERROR"),
              message: this.responseErrors,
              buttons: ["OK"],
            });
            await errorAlert.present();
        });

      this.loading = false;
    },
  },
};
