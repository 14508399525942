
import { alertController } from "@ionic/vue";
import { logIn, personAdd } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import { useRouter, useRoute } from "vue-router";
import ButtonGroup from "@/components/ButtonGroup.vue";
import MainHeader from "@/components/MainHeader.vue";
import FormError from "@/components/FormError.vue";

export default {
  name: "Login",
  components: {
    FormError,
    ButtonGroup,
    MainHeader,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    return {
      router,
      route,
      logIn,
      personAdd,
    };
  },
  data() {
    return {
      responseErrors: {},
      form: {
        email: "",
        token: this.route.query.token,
        password: "",
        password_confirmation: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "responseMessage",
      "responseCode",
    ]),
  },
  methods: {
    ...mapActions("auth", ["resetPassword"]),
    async handleSubmit() {
      this.responseErrors = {};
      this.resetPassword(this.form)
        .then(async () => {
          const successAlert = await alertController.create({
            header: this.$t("RESET_PASSWORD.SUCCESS_HEADER"),
            subHeader: this.$t("RESET_PASSWORD.SUCCESS_SUB_HEADER"),
            message: this.$t("RESET_PASSWORD.SUCCESS_MESSAGE"),
            buttons: ["OK"],
          });
          await successAlert.present();
        })
        .catch(async (err: any) => {
          this.responseErrors = err;
        });
    },
  },
};
