import { createStore } from 'vuex';
import { auth } from "./auth.store";
import { course } from "./course.store";

export const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        course
    },
})
