
import MainHeader from "@/components/MainHeader.vue";
import VerifyAlert from "@/components/VerifyAlert.vue";
import { mapGetters } from "vuex";
import { secondsToMinutesSeconds } from "@/helpers";

export default {
  name: "Dashboard",
  components: {
    MainHeader,
    VerifyAlert,
  },
  computed: {
    ...mapGetters("course", ["course"]),
    ...mapGetters("auth", ["currentUser", "stepAvailable", "stepCompleted"]),
    requiredModules() {
        if (this.course.modules) {
            const beforeModules = [
                {
                    name: this.$t("MENU.PRE_QUESTIONNAIRE"),
                    description: this.$t("MENU.PRE_QUESTIONNAIRE_DESCRIPTION"),
                    url: "/pre-questionnaire",
                    icon: require("@/assets/questionnaire.png"),
                    duration: false,
                    required: true,
                    disabled: ! this.stepAvailable('pre-questionnaire'),
                },
            ];
            const modules = this.course.modules.filter((m: any) => m.required == true)
                .map((m: any) => {
                    return {
                        name: m.name,
                        description: m.description,
                        url: '/course/' + m.course_order,
                        icon: this.getIcon(m),
                        duration: m.duration,
                        required: true,
                        disabled: ! this.stepAvailable(m.course_order),
                    }
                });

            const afterModules = [
                {
                    name: this.$t("MENU.POST_QUESTIONNAIRE"),
                    description: this.$t("MENU.POST_QUESTIONNAIRE_DESCRIPTION"),
                    url: "/post-questionnaire",
                    icon: require("@/assets/questionnaire.png"),
                    duration: false,
                    required: true,
                    disabled: ! this.stepAvailable('post-questionnaire'),
                },
            ];

            return beforeModules.concat(modules, afterModules)
        }

        return [];
    },
    optionalModules() {
        if (this.course.modules) {
            return this.course.modules.filter((m: any) => m.required != true)
                .map((m: any) => {
                    return {
                        name: m.name,
                        description: m.description,
                        url: '/course/' + m.course_order,
                        icon: this.getIcon(m),
                        duration: m.duration,
                        required: true,
                        disabled: ! this.stepAvailable(m.course_order),
                    }
                });
        }

        return [];
    }
  },
  methods: {
      getIcon(module: any) {
          if (module.course_order == 1) {
              return require("@/assets/introduction.png");
          }
          if (module.course_order == 2) {
              return require("@/assets/module-1.png");
          }
          if (module.course_order == 3) {
              return require("@/assets/module-2.png");
          }
          if (module.course_order == 4) {
              return require("@/assets/module-3.png");
          }
          if (module.course_order == 5) {
              return require("@/assets/module-4.png");
          }
          if (module.course_order == 6) {
              return require("@/assets/module-5.png");
          }
      },
      secondsToMinutesSeconds,
  }
};
