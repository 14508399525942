
import { ReferralService } from "@/services/referral.service";
import { ref } from "vue";
import { useRoute } from "vue-router";
import ButtonGroup from "@/components/ButtonGroup.vue";
import GuestHeader from "@/components/GuestHeader.vue";

export default {
  name: "Landing",
  components: {
    ButtonGroup,
    GuestHeader,
  },
  mounted() {
      this.$gtag.event('conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-11573830/child00e/lpg_20+standard'
      });
  },
  setup() {
    const route = useRoute();
    const landingContent = ref();
    if (route.query.referral) {
      ReferralService.saveReferral(route.query.referral.toString());
    }

    const clickToTop = () => {
      if (landingContent.value) {
        landingContent.value.$el.scrollToTop(1000);
      }
    }

    return {
        landingContent,
        clickToTop,
    }
  },
};
