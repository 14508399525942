
import MainHeader from "@/components/MainHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
import { mapGetters, mapActions} from "vuex";
import { secondsToMinutesSeconds } from "@/helpers";
import { vueVimeoPlayer } from "vue-vimeo-player";
import Player from '@vimeo/player'

export default {
  name: "Module",
  components: {
    MainHeader,
    vueVimeoPlayer,
  },
  data() {
    return {
      vimeoPlayer: undefined,
      secondsProgressed: 0.0,
      progressIntervalId: undefined,
      playerOptions: {
        responsive: true,
      },
      eventsToEmit: [
        'durationchange',
        'ended',
        'error',
        'pause',
        'play',
        'ready',
        'timeupdate',
      ],
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const courseStep = computed(() => route.params.module);

    return { router, courseStep };
  },

  computed: {
    ...mapGetters("course", ["course"]),
    module() {
      if (!this.course) {
        return {};
      }

      const module = this.course.modules.find((module: any) => {
        return module.course_order == this.courseStep;
      });

      if (! module) {
        // TODO route to 404 error page
        // this.router.push("/dashboard");
      }

      return module;
    },
  },
  unmounted() {
    // stop tracking progress
    this.stopTimer();
  },
  methods: {
    ...mapActions("course", ["progress"]),
    onReady() {
      this.secondsProgressed = this.module.position;
      if (this.secondsProgressed > 0 && ! this.module.completed) {
          this.$nextTick(function () {
            const element = document.querySelector('iframe[allowfullscreen]');
            const player = new Player(element);
            player.setCurrentTime(this.secondsProgressed);
          });
      }
    },
    onPlay() {
      console.log('Play started');
      this.trackPlayProgress();
    },
    onPause() {
      // Stop reporting to the server
      console.log('Paused');
      this.stopTimer();
    },
    onEnded() {
      // console.log('ended');
      this.stopTimer();
      // Post to the server
      // Go to next video or step
      this.progress({
        step: this.module.course_order,
        module_completed: true,
      }).then(() => {
        let next = 'course/' + (this.module.course_order + 1)

        if (this.module.course_order == 3) {
          next = 'post-questionnaire'
        } else if (this.module.course_order == 6) {
          next = 'certificate'
        }

        window.location.href = process.env.BASE_URL+next;
        // this.router.push(next)
      })
    },
    onTimeUpdate(time) {
      this.secondsProgressed = Math.round(time.seconds);
    },
    trackPlayProgress() {
      this.progressIntervalId = setInterval(() => {
        // console.log('Tell the server progress');
        // console.log(this.secondsProgressed);
        this.progress({
          step: this.module.course_order,
          position: this.secondsProgressed,
        })
      }, 10000);
    },
    stopTimer() {
      clearInterval(this.progressIntervalId);
      this.progressIntervalId = null;
    },
    getIcon(module: any) {
      if (module.course_order == 1) {
        return require("@/assets/introduction.png");
      }
      if (module.course_order == 2) {
        return require("@/assets/module-1.png");
      }
      if (module.course_order == 3) {
        return require("@/assets/module-2.png");
      }
      if (module.course_order == 4) {
        return require("@/assets/module-3.png");
      }
      if (module.course_order == 5) {
        return require("@/assets/module-4.png");
      }
      if (module.course_order == 6) {
        return require("@/assets/module-5.png");
      }
    },
    secondsToMinutesSeconds,
  },
};
