
import { toastController } from '@ionic/vue';
import { useStore } from "vuex";
import { computed } from 'vue'
import ApiService from "@/services/api.service";

export default {
  name: "VerifyAlert",
  setup() {
    const store = useStore()
    const currentUser = computed(() => store.state.auth.user);
    const resendVerifyEmail = async () => {
        if (currentUser.value) {
            ApiService.setLocale(currentUser.value.language);
        }
        const response = await ApiService.post("api/email/verification-notification", []).then(function (response) {
          return response.data;
        });

        const toast = await toastController
        .create({
          message: response.message,
          duration: 2000
        })
        toast.present();
    }

    return {
      currentUser,
      resendVerifyEmail,
    };
  },
}
