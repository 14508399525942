const REFERRAL_CODE = "referral_code";

const ReferralService = {
    getReferral() {
        return localStorage.getItem(REFERRAL_CODE);
    },

    saveReferral(referralCode: string) {
        localStorage.setItem(REFERRAL_CODE, referralCode);
    },

    removeReferral() {
        localStorage.removeItem(REFERRAL_CODE);
    },
};

export { ReferralService };
