
import { mapGetters, mapActions} from "vuex";
import ButtonGroup from "@/components/ButtonGroup.vue";
import ApiService from "@/services/api.service";
import MainHeader from "@/components/MainHeader.vue";
import FormError from "@/components/FormError.vue";

export default {
  name: "PreQuestionnaire",
  components: {
    ButtonGroup,
    MainHeader,
    FormError,
  },
  data() {
    return {
      questions: {},
      answers: {},
      responseErrors: [],
    };
  },
  mounted() {
    this.loadQuestions();
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    selectOne() {
        return this.$t("SELECT_ONE");
    }
  },
  methods: {
    ...mapActions("auth", ["getCurrentUser"]),
    async loadQuestions() {
      if (this.currentUser) {
        ApiService.setLocale(this.currentUser.language);
      }
      const questions = await ApiService.get("api/pre-course-survey").then(function (response) {
        return response.data.data;
      });

      this.questions = questions;
    },
    async handleSubmit() {
      const response = await ApiService.post(
        "api/pre-course-survey",
        this.answers
      ).then(response => {
        this.currentUser.pre_survey = true
        this.getCurrentUser()
      }).catch(err => {
        this.responseErrors = err.response.data.errors;
      });
    },
  },
};
