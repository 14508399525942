
import { alertController } from "@ionic/vue";
import { logIn, personAdd } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import ButtonGroup from "@/components/ButtonGroup.vue";
import MainHeader from "@/components/MainHeader.vue";

export default {
  name: "Login",
  components: {
    ButtonGroup,
    MainHeader,
  },
  mounted() {
      this.$gtag.event('conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-11573830/child00i/res_20+standard'
      });
  },
  setup() {
    const router = useRouter();
    return {
      router,
      logIn,
      personAdd,
    };
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "responseMessage",
      "responseCode",
    ]),
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    async handleLogin() {
      this.signIn(this.form)
        .then(() => {
          this.form.email = "";
          this.form.password = "";
          this.router.push("/dashboard");
        })
        .catch(async (err: any) => {
          const errorAlert = await alertController.create({
            header: "Failed",
            subHeader: "Sign in Failed",
            message: err,
            buttons: ["OK"],
          });
          await errorAlert.present();
        });
    },
  },
};
