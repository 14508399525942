
import { computed } from "vue";
import { useStore, mapGetters } from "vuex";
import MainHeader from "@/components/MainHeader.vue";
import { Share } from '@capacitor/share';
import { isPlatform } from '@ionic/vue';

export default {
  name: "Referrals",
  components: {
    MainHeader,
  },
  setup() {
    const store = useStore();
    const baseUrl = window.origin;
    const currentUser = computed(() => store.state.auth.user);

    return {
      baseUrl,
      currentUser,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    subject() {
        return this.$t('REFERRALS.SUBJECT');
    },
    body() {
        let body = this.$t('REFERRALS.BODY');
        body += this.currentUser.referral_code;
        console.log(body);
        return body;
    },
    isBrowser() {
        return isPlatform('mobileweb') || isPlatform('desktop');
    }
  },
  methods: {
      async share() {
          await Share.share({
            title: this.$t("REFERRALS.SUBJECT"),
            text: this.$t("REFERRALS.BODY"),
            url: this.baseUrl + '?referral=' + this.currentUser.referral_code,
            dialogTitle: this.$t("REFERRALS.HEADING"),
          });
      }
  }
};
