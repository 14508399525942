function pad(num: any) {
   return ("0"+num).slice(-2);
}

function secondsToMinutesSeconds(secs: number) {
    let minutes = Math.floor(secs / 60);
    secs = secs%60;
    minutes = minutes%60;
    return `${pad(minutes)}:${pad(secs)}`;
}

export {
    pad,
    secondsToMinutesSeconds
}
