
import {
  IonText,
} from "@ionic/vue";

export default {
  name: "FormError",
  components: {
    IonText,
  },
  props: {
      errors: {
        type: Array,
        default: () => [],
      },
  }
}
