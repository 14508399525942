import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, { color: "warning" }, {
    default: _withCtx(() => [
      _createElementVNode("p", null, [
        _createTextVNode(_toDisplayString(_ctx.$t("ACCOUNT.VERIFY_ALERT")) + " ", 1),
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($setup.resendVerifyEmail && $setup.resendVerifyEmail(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.$t("ACCOUNT.RESEND")), 1)
      ])
    ]),
    _: 1
  }))
}