import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dropdown, {
      modelValue: $data.selected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.selected) = $event)),
      options: $data.options,
      optionLabel: "name",
      optionValue: "id",
      placeholder: $data.placeholder,
      loading: $data.loading,
      filter: true,
      showClear: true,
      onFilter: $options.fetchResults,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $data.selected)))
    }, null, 8, ["modelValue", "options", "placeholder", "loading", "onFilter"])
  ]))
}