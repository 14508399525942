import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!

  return ($props.errors)
    ? (_openBlock(), _createBlock(_component_ion_text, {
        key: 0,
        color: "danger"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.errors, (message, i) => {
            return (_openBlock(), _createElementBlock("small", { key: i }, _toDisplayString(message), 1))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}