<template>
    <div class="button-group">
        <slot></slot>
    </div>
</template>

<style scoped>
.button-group {
  width: 320px;
  margin: 0px auto;
}
</style>
