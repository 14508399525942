
import {
  alertController,
} from "@ionic/vue";
import ApiService from "@/services/api.service";
import { logIn, personAdd } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import ButtonGroup from "@/components/ButtonGroup.vue";
import MainHeader from "@/components/MainHeader.vue";
import FormError from "@/components/FormError.vue";
import TypeAhead from "@/components/TypeAhead.vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { ReferralService } from '@/services/referral.service';

export default {
  name: "Register",
  components: {
    ButtonGroup,
    MainHeader,
    FormError,
    TypeAhead
  },
  mounted() {
    this.populateGrades();
    const { locale } = useI18n();
    this.form.language = locale;
    this.form.referredBy = ReferralService.getReferral();

    this.$gtag.event('conversion', {
      'allow_custom_scripts': true,
      'send_to': 'DC-11573830/child00g/res_20+standard'
    });
  },
  setup() {
    const router = useRouter();

    return {
      router,
      logIn,
      personAdd,
    };
  },
  data() {
    return {
      grades: [],
      otherSchoolVisible: false,
      form: {
        email: "",
        password: "",
        name: "",
        gradeId: "",
        districtId: null,
        schoolId: null,
        otherSchool: "",
        language: "",
        referredBy: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", [
      "responseErrors",
      "responseCode",
      "responseMessage",
    ]),
    selectOne() {
      return this.$t('SELECT_ONE');
    },
    district() {
      return this.$t('DISTRICT');
    },
    school() {
      return this.$t('SCHOOL');
    }
  },
  methods: {
    ...mapActions("auth", ["signUp", "signIn"]),
    async populateGrades() {
      const grades = await ApiService.get("api/grades").then(function (
        response
      ) {
        return response.data.data;
      });

      this.grades = grades;
    },
    showOtherSchool(visible = true) {
      this.form.otherSchool = null
      this.form.districtId = null
      this.form.schoolId = null
      this.otherSchoolVisible = visible
    },
    async handleSignup() {
      const registerUser = {
        name: this.form.name,
        email: this.form.email,
        password: this.form.password,
        gradeId: this.form.gradeId,
        otherSchool: this.form.otherSchool,
        districtId: this.form.districtId,
        schoolId: this.form.schoolId,
        language: this.form.language,
        referredBy: this.form.referredBy,
      };
      this.signUp(registerUser)
      .then(async () => {
        if (this.responseCode == 200) {
          // Login to app
          this.signIn({
            email: this.form.email,
            password: this.form.password
          })
          .then(() => {
            this.router.push("/dashboard");
          })
          .catch(async (err: any) => {
            const errorAlert = await alertController.create({
              header: "Failed",
              subHeader: "Sign in Failed",
              message: err,
              buttons: ["OK"],
            });
            await errorAlert.present();
          });
        }

        console.log(this.responseError);
      })
      .catch((err: any) => {
        console.log(err);
      });
    },
  },
};
