
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/vue";
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import SideMenu from "@/components/SideMenu.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    SideMenu,
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  mounted() {
    if (this.currentUser && this.currentUser.language) {
      this.$i18n.locale = this.currentUser.language;
    }
  },
});
