
import Dropdown from "primevue/dropdown";
import axios from "axios";

export default {
  components: {
    Dropdown,
  },
  props: {
    modelValue: {
      type: Number,
      default: null,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      options: [],
      loading: false,
      placeholder: "Search",
    };
  },
  mounted() {
    this.loading = true;
    this.placeholder = this.$t("LOADING");
    this.fetchResults();
  },
  methods: {
    fetchResults(query: any) {
      let baseUrl = this.endpoint;
      if (query) {
        baseUrl = baseUrl + `?query=${query.value}`;
      }
      axios.get(baseUrl).then((res) => {
        this.options = res.data.data;
        this.loading = false;
        this.placeholder = this.$t("SELECT")+" "+ this.name;
      });
    },
  },
};
