
import {
  ellipseOutline,
  exitOutline,
  checkmarkCircleOutline,
  trophyOutline,
  shareSocialOutline,
} from "ionicons/icons";
import { useStore, mapGetters } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";

export default {
  name: "SideMenu",
  components: {},
  setup() {
    const store = useStore();

    const userLinks = computed(() => [
      //     {
      //     title: "Logout",
      //     url: "/logout",
      //     iosIcon: exitOutline,
      //     mdIcon: exitSharp,
      //   },
    ]);
    const route = useRoute();

    const currentUser = computed(() => store.state.auth.user);
    const selectedPath = computed(() => route.path);
    const router = useRouter();

    const handleSignOut = async () => {
      store.dispatch("auth/signOut");
    };

    return {
      currentUser,
      userLinks,
      selectedPath,
      router,
      handleSignOut,
    };
  },
  computed: {
    ...mapGetters("course", ["course"]),
    ...mapGetters("auth", ["currentUser", "stepAvailable", "stepCompleted"]),
    userLogo() {
      if (this.currentUser) {
        if (this.currentUser.language == "es") {
          return require("@/assets/CMI_HealthyMindsThrivingKids_Logo_FullColor_Sp.svg");
        }
      }

      return require("@/assets/CMI_HealthyMindsThrivingKids_Logo_FullColor.svg");
    },
    exitOutline() {
      return exitOutline;
    },
    incentiveProgress() {
      if (!this.course) {
        return [];
      }

      const modules = this.course.modules
      .filter((m: any) => m.required == true)
      .map((module: any) => {
        const completed = this.stepCompleted(module.course_order)
        return {
          title: module.name,
          url: "/course/" + module.course_order,
          required: module.required,
          iosIcon: completed ? checkmarkCircleOutline : ellipseOutline,
          mdIcon: completed ? checkmarkCircleOutline : ellipseOutline,
          disabled: ! this.stepAvailable(module.course_order),
        };
      });

      const beforeModules = [
        {
          title: this.$t("MENU.PRE_QUESTIONNAIRE"),
          url: "/pre-questionnaire",
          required: true,
          disabled: false,
          iosIcon: this.currentUser.pre_survey
          ? checkmarkCircleOutline
          : ellipseOutline,
          mdIcon: this.currentUser.pre_survey
          ? checkmarkCircleOutline
          : ellipseOutline,
        },
      ];
      const afterModules = [
        {
          title: this.$t("MENU.POST_QUESTIONNAIRE"),
          url: "/post-questionnaire",
          required: true,
          disabled: ! this.stepAvailable('post-questionnaire'),
          iosIcon: this.currentUser.post_survey
          ? checkmarkCircleOutline
          : ellipseOutline,
          mdIcon: this.currentUser.post_survey
          ? checkmarkCircleOutline
          : ellipseOutline,
        },
        {
          title: this.$t("MENU.INCENTIVES"),
          url: "/incentives",
          required: false,
          disabled: ! this.stepAvailable('incentives'),
          iosIcon: this.currentUser.order
          ? checkmarkCircleOutline
          : ellipseOutline,
          mdIcon: this.currentUser.order
          ? checkmarkCircleOutline
          : ellipseOutline,
        },
        {
          title: this.$t("MENU.REFERRALS"),
          url: "/referrals",
          required: false,
          disabled: ! this.stepAvailable('referrals'),
          iosIcon: shareSocialOutline,
          mdIcon: shareSocialOutline,
        },
      ];

      return beforeModules.concat(modules, afterModules);
    },
    certificateProgress() {
      if (!this.course) {
        return [];
      }

      const modules = this.course.modules
      .filter((m: any) => m.required == false)
      .map((module: any) => {
        const completed = this.stepCompleted(module.course_order)
        return {
          title: module.name,
          url: "/course/" + module.course_order,
          required: module.required,
          iosIcon: completed ? checkmarkCircleOutline : ellipseOutline,
          mdIcon: completed ? checkmarkCircleOutline : ellipseOutline,
          disabled: ! this.stepAvailable(module.course_order),
        };
      });

      const afterCert = [
        {
          title: this.$t("MENU.CERTIFICATE"),
          url: "/certificate",
          required: false,
          disabled: ! this.stepAvailable('certificate'),
          iosIcon: trophyOutline,
          mdIcon: trophyOutline,
        }
      ];

      return modules.concat(afterCert);
    },
  },
};
