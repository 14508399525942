import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "relative w-full"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "ion-float-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_vue_vimeo_player = _resolveComponent("vue-vimeo-player")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      ($options.module)
        ? (_openBlock(), _createBlock(_component_main_header, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($options.module.name), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          ($options.module)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_vue_vimeo_player, {
                  ref: "vueVimeo",
                  "video-url": $options.module.url,
                  "events-to-emit": $data.eventsToEmit,
                  options: $data.playerOptions,
                  onReady: $options.onReady,
                  onPlay: $options.onPlay,
                  onPause: $options.onPause,
                  onEnded: $options.onEnded,
                  onTimeupdate: $options.onTimeUpdate
                }, null, 8, ["video-url", "events-to-emit", "options", "onReady", "onPlay", "onPause", "onEnded", "onTimeupdate"])
              ]))
            : _createCommentVNode("", true),
          ($options.module)
            ? (_openBlock(), _createBlock(_component_ion_item, {
                key: 1,
                color: "magenta",
                class: "ion-margin-bottom"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_grid, { class: "ion-padding" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, { class: "ion-align-items-center ion-justify-content-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            class: "ion-align-self-center ion-text-center",
                            sizeLg: "2",
                            sizeMd: "2",
                            sizeXs: "4"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: $options.getIcon($options.module),
                                class: "w-24 mx-auto"
                              }, null, 8, _hoisted_2)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, {
                            class: "ion-margin-bottom ion-padding",
                            sizeLg: "10",
                            sizeMd: "10",
                            sizeXs: "12"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("h3", null, [
                                _createTextVNode(_toDisplayString($options.module.name) + " ", 1),
                                ($options.module.duration)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($options.secondsToMinutesSeconds($data.secondsProgressed)) + " / " + _toDisplayString($options.secondsToMinutesSeconds($options.module.duration)), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($options.module.description), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}